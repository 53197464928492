/* @import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';
 */
.hesap-bilgileri-title {
  border: 1px solid #d8d6de;
  height: 100%;
  padding: 0.571rem 1rem;
  text-wrap: nowrap;
  background-color: #f7f7f7c2;
}

.select__menu {
  z-index: 99999 !important;
}
.fixed-right-bar {
  position: fixed;
  right: 1rem;
  height: 100%;
}

.vertical-divider {
  width: 1px;
  margin: 0 1rem;
  background-color: #d8d6de;
  padding: 0;
}
.loader {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #1111, $alpha: 0.1);
  display: grid;
  place-items: center;
}
.bg-tab-content {
  background-color: rgba(201, 215, 221, 0.2);
}
.odeme-alani {
  padding: 0.5rem 0.3rem;
}
.swal2-actions {
  gap: 1rem;
}
.doviz-ekrani {
  height: 100dvh;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  header {
    border-bottom: 1px solid rgba($color: #e3e1d9, $alpha: 1);
    height: 100px;
    display: flex;
    align-items: center;
    .header-logo {
      font-size: 24px;
      font-weight: 500;
      color: #387adf;
    }
  }
  .doviz-footer {
    height: 50px;
    background-color: #fff;
    border-top: 1px solid rgba($color: #e3e1d9, $alpha: 1);
    display: flex;
    align-items: center;
  }
  .main {
    flex: 1;
    width: 100%;
  }
}
@media (max-width: 990px) {
  .doviz-ekrani-list {
    margin-bottom: 20px;

  }
  .doviz-list-view {
    height: auto !important;

  }

  .currency-type-button {
    padding: 5px, 10px, 5px, 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.3);
    &.active {
      color: #fff;
      background-color: #1a3b71;
    }
  }
  .doviz-ekrani-list-mobile {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .list-title{
        padding:  30px 0 20px 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        .up-arrow{
            display: none;
        }
        &.up{
            .price{
                color: #58BD7D;
            }
            .up-arrow{
                display: block;
            }
            .down-arrow{
                display: none;
            }
        } 
        &.down{
            .price{
                color: #D33535;
            }
            .up-arrow{
                display: none;
            }  
            .down-arrow{
                display: block;
            }
        } 
    }
    .list-title-first{
        border-bottom-color: transparent;
    }
  }
}
.doviz-ekrani-list {
  width: 100%;
  .doviz-list-view {
    height: calc(100dvh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: repeat(8, 1fr);

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid transparent;
    }
  }
  .list-title {
    .list-title-col {
      padding-left: 0;
      padding-right: 0;
      border-right: 1px solid rgba($color: #222, $alpha: 0.3);

      &:last-child .list-title-item {
        border-right: 0;
      }
    }
    .list-title-item {
      font-size: 14px;
      font-weight: 400;
      background-color: #1b3c73;
      color: #fff;
      padding: 10px 5px;
      border-right: 1px solid #fff;
    }
  }
  .list-item {
    position: relative;
    border-bottom: 1px solid rgba($color: #222, $alpha: 0.3);
    
    .list-item-view {
      padding: 5px 5px;
      text-align: right;
      font-size:20px;
      font-weight: 600;
      &.number{
        font-size: 32px;
        font-weight: 500;
      }
    }
    .list-active-status {
      width: 5px;
      height: 90%;
      &.up {
        background-color: green;
      }
      &.down {
        background-color: red;
      }
    }
  }
}
.horizontal-menu
  .header-navbar.navbar-horizontal
  ul#main-menu-navigation
  > li
  svg {
  width: 12px !important;
  height: 12px !important;
}
.barcode-tag {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  .tag-input {
    border: none;
    padding: 0.571rem 1rem;
    border-radius: 0.357rem;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .tags-area {
    padding: 0.571rem 1rem;
  }
  .tags-area-items {
    padding: 0.26rem 0.6rem;
    border-radius: 0.357rem;
    background-color: #7367f0;
    color: #fff;
  }
}
@media (max-width: 990px) {
  .container {
    max-width: 100% !important;
  }
}


@keyframes flash {
  0%, 100% {
    color: 1;
  }
  50% {
    color: .6;
  }
}

.text-green {
  color: green;
  animation: flash 1s ease-in-out ;
}

.text-red {
  color: red;
  animation: flash 1s ease-in-out ;
}
